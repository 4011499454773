<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" label="New admission" align="center" />
      
        <Content class="my-3" label="Description" text="New admission for summer 2019" />
        <Content class="my-3" label="Reason" text="Registration" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
            <Content class="my-3" label="Branch" text="Dhanmondi" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Created By" text="Kazi Shahin" />
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
            <Content class="my-3" label="Views" text="12,258" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Duration" text="7 Days" />
            </div>
        </div>
       
        <Content class="my-10" label="Status" text="Active" text-color="text-green" />
        <div class="flex justify-center align-center">
                <md-button class="bg-default text-uppercase rounded" @click="dialog(false)">Back</md-button>
                <md-button class="bg-victoria text-white text-uppercase rounded">Go to edit page</md-button>
        </div>
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  methods: {
      ...mapMutations({
      dialog: "setShowDialog"
    }),
  }
}
</script>