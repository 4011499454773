<template>
  <div class="p-6">
    <DialogTitle label="Create Campaign" align="center" />
    <form @submit.prevent="onSubmit">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-50 mb-3">
          <InputFieldComponent
            italic
            label="Title"
            v-model.trim="$v.form.title.$model"
            :message="!$v.form.title.required && $v.form.title.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-50 mb-3">
          <InputFieldComponent
            italic
            label="Reason"
            v-model.trim="$v.form.reason.$model"
            :message="!$v.form.reason.required && $v.form.reason.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-30 mb-3">
          <InputFieldComponent
            italic
            label="Duration"
            v-model.trim="$v.form.duration.$model"
            :message="!$v.form.duration.required && $v.form.duration.$dirty ? 'Field is required' : null"
          />
        </div>
       
        <div class="md-layout-item md-size-25 mb-3">
          <SelectComponent
            label="Section"
            :placeholder="false"
            :items="sections"
            italic
            class="w-full"
            v-model.trim="$v.form.section.$model"
            :message="!$v.form.section.required && $v.form.section.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-25 mb-3">
          <SelectComponent
            label="Branch"
            :placeholder="false"
            :items="branches"
            italic
            class="w-full"
            v-model.trim="$v.form.branch.$model"
            :message="!$v.form.branch.required && $v.form.branch.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-80 mb-3 mt-4">
          <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Short Description</label>
          <TextAreaComponent
            v-model.trim="$v.form.description.$model"
            :message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-50 mb-3">
          <div class="">
          <label class="text-uppercase text-base font-semibold mb-1 block text-italic">Campaign For</label>
            <md-checkbox
              v-model="form.compaign"
              value="1"
            >
             APICAL PORTAL
            </md-checkbox>
           
            <md-checkbox
                v-model="form.compaign"
                value="2"
            >
             Twitter
            </md-checkbox>
           <br>
            <md-checkbox
                v-model="form.compaign"
                value="3"
            >
             APICAL Website
            </md-checkbox>
           
            <md-checkbox
              v-model="form.compaign"
              value="4"
            >
             Linkedin
            </md-checkbox>
            <br>
            <md-checkbox
            v-model="form.compaign"
            value="5"
            >
             Facebook
            </md-checkbox>
          </div>
          <p id="scrollTop" class="text-xs text-danger" v-if="!$v.form.compaign.required && $v.form.compaign.$dirty">Field is required</p>
        </div>
        <div class="flex justify-end align-center mb-3">
          <md-button class="md-default">Save As Draft</md-button>
          <md-button class="border border-solid border-gray-500 rounded"
            >Schedule</md-button
          >
          <md-button type="submit" class="bg-victoria text-white rounded">Create</md-button>
        </div>
      </form>
  </div>
</template>

<script>
import {
    InputFieldComponent,
    SelectComponent,
    DialogTitle,
    TextAreaComponent
} from "@/components";
import cms from "@/data/cms";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    SelectComponent,
    TextAreaComponent,
    InputFieldComponent,
  },
  data() {
    return {
      value: true,
      sections: cms.sections,
      branches: cms.branches,
      form: {
        title: "",
        reason: "",
        duration: "",
        section: "",
        branch: "",
        description: "",
        compaign: []
      }
    };
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();

      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Campaign saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    },
  },
  validations: {
      form: {
        title: {required},
        reason: {required},
        duration: {required},
        section: {required},
        branch: {required},
        description: {required},
        compaign: {required},
      }
    }
};
</script>

<style></style>
